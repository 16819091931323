@import url("https://fonts.googleapis.com/css2?family=Manrope&family=Roboto&display=swap");



*,
::before,
::after {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Segoe UI";
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid blue;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.esri-view-surface.esri-view-surface--touch-none:focus{
  outline: none;

  &::after{
    display: none;
  }
}

.mb-4{
  margin-bottom: 4rem;
}

textarea {
  resize: none;
}

.Toastify__toast-container {
  z-index: 100000000000000000;
}